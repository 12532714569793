import { setLanguage } from "core/i18n/utils"
import { ActionUserInfo, Theme } from "library/common/actions/user"
import { Modals } from "library/common/reducers/modalsReducer"
import { ModalType } from "library/common/types/modalTypes"
import { hideToken, isDashboard } from "library/utilities/urls"
import { registerScreenshotListener } from "library/utilities/screenshot"
import * as React from "react"
import { useHistory } from "react-router-dom"

export interface ParamContextValues {
  sourceParam: string
  directParam: string
  showParam: string
  boneLossParam: string
  hsmParam: string
  dynamicPblParam: string
  demoParam: string
  handlerParam: string
  tokenParam: string
  langParam: string
  themeTestParam: string
  legacyLoginParam: string
  codeParam: string
  errorParam: string
  providerParam: string
  resultParam: string
  displayFlippedButtonParam: string
  reportParam: string
  companyIdParam?: string
  patientIdParam?: string
  doctorIdParam?: string
  isIteroUser: boolean
  isIteroMidcUser: boolean
  impersonateParam?: string
}

export const ParamContext = React.createContext<ParamContextValues | undefined>(
  undefined
)

export interface IUrlParamsContext {
  children: React.ReactNode
  theme: Theme
  isHSMAvailable: boolean
  isHighSenseModeAllowed: boolean
  setUserInfo: (value: ActionUserInfo) => unknown
  setLang: (lang: string) => void
  openModal: (type: ModalType) => void
  setHSM: (value: boolean) => void
}

export const UrlParamContext = ({
  children,
  theme,
  isHSMAvailable,
  isHighSenseModeAllowed,
  setUserInfo,
  setLang,
  openModal,
  setHSM,
}: IUrlParamsContext) => {
  const params = React.useMemo(() => new URLSearchParams(location.search), [])
  const [tokenParam, setTokenParam] = React.useState("")
  const getParam = (value: string) => params.get(value) || ""
  const history = useHistory()

  const companyIdRaw = params.get("companyId")
  const doctorIdRaw = params.get("doctorId")
  const patientIdRaw = params.get("patientId")

  const sourceParam = getParam("source").toLowerCase()
  const directParam = getParam("direct")
  const showParam = getParam("show")
  const langParam = getParam("lang")
  const boneLossParam = getParam("boneLoss")
  const hsmParam = getParam("hsm")
  const dynamicPblParam = getParam("dynamicPbl")
  const demoParam = getParam("demo")
  const handlerParam = getParam("handler")
  const themeTestParam = getParam("themeTest")
  const legacyLoginParam = getParam("legacyLogin")
  const codeParam = getParam("code")
  const errorParam = getParam("error")
  const providerParam = getParam("provider")
  const resultParam = getParam("result")
  const reportParam = getParam("report")
  const displayFlippedButtonParam = getParam("displayFlippedButton")
  // Axios omits the param if value is undefined, otherwise it includes key with empty value for null or ""
  const companyIdParam = companyIdRaw || undefined
  const doctorIdParam = doctorIdRaw || undefined
  const patientIdParam = patientIdRaw || undefined
  const impersonateParam = params.get("impersonate") || undefined

  const isIteroUser = sourceParam === "desktop" || sourceParam === "web"
  const isIteroMidcUser = sourceParam === "web"
  const isIteroScannerUser = sourceParam === "desktop"

  React.useEffect(() => {
    setUserInfo({
      companyId: companyIdParam,
      doctorId: doctorIdParam,
      isIteroUser,
      isIteroMidcUser,
      isIteroScannerUser,
    })
  }, [
    companyIdParam,
    setUserInfo,
    doctorIdParam,
    isIteroUser,
    isIteroMidcUser,
    isIteroScannerUser,
  ])

  React.useEffect(() => {
    setLanguage(langParam, setLang, theme)
  }, [langParam, setLang, theme])

  React.useEffect(() => {
    const token = params.get("token")
    if (token) {
      setTokenParam(token)
      hideToken(params)
    }
  }, [params])

  React.useEffect(() => {
    if (hsmParam !== "1" || !isHighSenseModeAllowed) {
    } else if (isHSMAvailable) {
      setHSM(true)
    } else if (isDashboard(history.location)) {
      openModal({ openedModal: Modals.ReanalyseAlert })
    }
  }, [
    hsmParam,
    isHSMAvailable,
    openModal,
    setHSM,
    isHighSenseModeAllowed,
    history,
  ])

  React.useEffect(() => {
    if (patientIdParam) return registerScreenshotListener(patientIdParam)
  }, [patientIdParam])

  return (
    <ParamContext.Provider
      value={{
        sourceParam,
        directParam,
        showParam,
        boneLossParam,
        hsmParam,
        dynamicPblParam,
        demoParam,
        handlerParam,
        tokenParam,
        langParam,
        themeTestParam,
        legacyLoginParam,
        codeParam,
        errorParam,
        providerParam,
        resultParam,
        displayFlippedButtonParam,
        reportParam,
        companyIdParam,
        patientIdParam,
        doctorIdParam,
        isIteroUser,
        isIteroMidcUser,
        impersonateParam,
      }}
    >
      {children}
    </ParamContext.Provider>
  )
}

export const useUrlParamContext = () => {
  const context = React.useContext(ParamContext)

  if (!context) {
    throw new Error("Cannot be used without ParamContext")
  }

  return context
}
