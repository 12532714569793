import { createAction } from "typesafe-actions"

import {
  CurrentFmxResult,
  FullMouthImagesResult,
  FullMouthTypes,
} from "../types/fullMouthTypes"
import { IAnalysisData } from "../sagas/imageSaga"
import { ResultStatus } from "../types/dataStructureTypes"

export const setInitialFullMouthState = createAction(
  FullMouthTypes.SetInitialFullMouthState
)()
export const setCurrentFmx = createAction(
  FullMouthTypes.SetCurrentFmx
)<CurrentFmxResult>()
export const setCurrentFmxResultStatus = createAction(
  FullMouthTypes.SetCurrentFmxResultStatus
)<ResultStatus>()
export const requestFullMouth = createAction(
  FullMouthTypes.RequestFullMouth
)<string>()
export const setFullMouthImages = createAction(
  FullMouthTypes.SetFullMouthImages
)<FullMouthImagesResult>()
export const setFullMouthImagesResultStatus = createAction(
  FullMouthTypes.SetFullMouthImagesResultStatus
)<ResultStatus>()
export const setFullMouthResults = createAction(
  FullMouthTypes.SetFullMouthResults
)<IAnalysisData[]>()
export const setActiveFullMouthImage = createAction(
  FullMouthTypes.SetActiveFullMouthImage
)<string | null>()
export const setShowFullMouthAnnotations = createAction(
  FullMouthTypes.SetShowFullMouthAnnotations
)<boolean>()
