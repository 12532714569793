import { Tooth } from "@dentalxrai/transform-landmark-to-svg"
import { Detection } from "./dataStructureTypes"
import { ActionWithData } from "../actions/upload"

export enum UploadTypes {
  UploadActionNewImage = "@@Upload/UploadActionNewImage",
  UploadActionPreloaderImage = "@@Upload/UploadActionPreloaderImage",
  UploadActionError = "@@Upload/UploadActionError",
  UploadActionDataImageSuccess = "@@Upload/UploadActionDataImageSuccess",
  UploadActionWithData = "@@Upload/UploadActionWithData",
  UploadActionSuccess = "@@Upload/UploadActionSuccess",
  UploadActionSaveUserMetadata = "@@Upload/UploadActionSaveUserMetadata",
  UploadActionSendReportProblem = "@@Upload/UploadActionSendReportProblem",
  UploadActionSetReportProblemId = "@@Upload/UploadActionSetReportProblemId",
  UploadActionSetShouldTakeScreenshot = "@@Upload/UploadActionSetShouldTakeScreenshot",
  UploadActionSetScreenshot = "@@Upload/UploadActionSetScreenshot",
  UploadActionSetImageSizeError = "@@Upload/UploadActionSetImageSizeError",
  SetCurrentBatchUpload = "@@Upload/SetCurrentBatchUpload",
  SetTotalBatchUpload = "@@Upload/SetTotalBatchUpload",
  ClearUploadFiles = "@@Upload/ClearUploadFiles",
  RemoveAcceptedFile = "@@Upload/RemoveAcceptedFile",
  SetAcceptedFiles = "@@Upload/SetAcceptedFiles",
  SetRejectedFiles = "@@Upload/SetRejectedFiles",
  SetConflictedFiles = "@@Upload/SetConflictedFiles",
  ClearConflictedFile = "@@Upload/ClearConflictedFile",
}

export interface ICurrentBatchUpload {
  total: number | null
  error: string[] | null
  success: string[] | null
}

export interface ToothWithDetection {
  toothName: string
  apical: boolean
  bone: boolean
  bridges: boolean
  caries: boolean
  crowns: boolean
  roots: boolean
  fillings: boolean
  implants: boolean
  toothDelete: boolean
  comment: string
}

export type DataImage = Readonly<{
  apical: Detection[]
  caries: Detection[]
  restorations: Detection[]
  paro: Detection[]
  teeth: Tooth[]
}>

export enum ErrorType {
  FileType = "FileType",
  MinSize = "MinSize",
  MaxSize = "MaxSize",
}
export interface IAcceptedFiles {
  file: ActionWithData
  preview: string
}
export interface IRejectedFiles {
  fileName: string
  errorType: ErrorType
  preview?: string
}
export interface IUploadFiles {
  acceptedFiles: IAcceptedFiles[]
  rejectedFiles: IRejectedFiles[]
  conflictedFiles: IConflictedFile[]
}
export interface PatientMeta {
  patientID: string
  patientName: string
  dateOfBirth: string
}
export interface IConflictedFile {
  currentPatientInfo: PatientMeta
  imagePatientInfo: PatientMeta
  dateOfRadiograph: string
  fileName: string
  id: string
}
