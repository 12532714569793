import { ActionType, getType } from "typesafe-actions"
import * as actions from "library/common/actions/fullMouth"
import {
  CurrentFmxResult,
  FullMouthImagesResult,
} from "../types/fullMouthTypes"
import { IAnalysisData } from "../sagas/imageSaga"
import { ResultStatus } from "../types/dataStructureTypes"

export type FullMouthState = Readonly<{
  radiographSet: CurrentFmxResult
  fullMouthImages: FullMouthImagesResult
  fullMouthResults: IAnalysisData[]
  activeFullMouthImage: string | null
  showFullMouthAnnotations: boolean
}>

export const initialFullMouthState: FullMouthState = {
  radiographSet: {
    set: null,
    resultStatus: ResultStatus.None,
  },
  fullMouthImages: {
    images: [],
    resultStatus: ResultStatus.None,
  },
  fullMouthResults: [],
  activeFullMouthImage: null,
  showFullMouthAnnotations: false,
}
type FullMouthActions = ActionType<typeof actions>

export default (
  state = initialFullMouthState,
  action: FullMouthActions
): FullMouthState => {
  switch (action.type) {
    case getType(actions.setInitialFullMouthState):
      return {
        ...initialFullMouthState,
      }

    case getType(actions.setCurrentFmx):
      return { ...state, radiographSet: action.payload }

    case getType(actions.setCurrentFmxResultStatus):
      return {
        ...state,
        radiographSet: {
          ...state.radiographSet,
          resultStatus: action.payload,
        },
      }

    case getType(actions.setFullMouthImages):
      return {
        ...state,
        fullMouthImages: action.payload,
      }

    case getType(actions.setFullMouthImagesResultStatus):
      return {
        ...state,
        fullMouthImages: {
          ...state.fullMouthImages,
          resultStatus: action.payload,
        },
      }

    case getType(actions.setFullMouthResults):
      return { ...state, fullMouthResults: action.payload }

    case getType(actions.setActiveFullMouthImage):
      return { ...state, activeFullMouthImage: action.payload }

    case getType(actions.setShowFullMouthAnnotations):
      return { ...state, showFullMouthAnnotations: action.payload }

    default:
      return state
  }
}
