import { call, put, takeLatest, all, select } from "redux-saga/effects"
import { FullMouthTypes } from "../types/fullMouthTypes"
import { requestGetRadiographSet } from "library/services/xraysApi"
import { setImageIDBreadcrumb } from "../actions/breadcrumbs"
import { RadiographSet } from "../types/patientTypes"
import {
  setCurrentFmx,
  setCurrentFmxResultStatus,
  setFullMouthImages,
  setFullMouthImagesResultStatus,
  setFullMouthResults,
} from "../actions/fullMouth"
import { requestImageAnalysis } from "library/services/imageApis"
import { cleanCachedImageSaga, IAnalysisData } from "./imageSaga"
import { Detection, ResultStatus } from "../types/dataStructureTypes"
import { AnnotationName } from "../types/adjustmentTypes"
import { getRevertVersion } from "../selectors/features"
import { setShownRadiographAnnotations } from "../actions/image"

function* requestFullMouthImagesSaga(radiographs: string[]) {
  yield put(setFullMouthImagesResultStatus(ResultStatus.Loading))
  const revertVersion: boolean = yield select(getRevertVersion)

  try {
    // Use the radiograph ids to request the image analysis results for each radiograph
    const extractedData: IAnalysisData[] = yield all(
      radiographs.map((resultId) =>
        call(function* () {
          const { data } = yield call(requestImageAnalysis, resultId, {
            showHistory: revertVersion,
          })
          return data
        })
      )
    )

    // Extract the relevant data for each radiograph id.
    const extractedImageData = extractedData.map((e) => {
      const { id, meta, apical, caries, calculus, restorations, additions } = e

      const extractedMask = (detection?: Detection[]) =>
        detection
          ?.filter((d) => !d.subtype.includes("_HSM")) // We are not considering the HSM candidates for now
          .map((d) => d.mask) || []

      const extractedAdditions = (detection?: AnnotationName) =>
        additions?.flatMap((a) => {
          return (a.type === detection && a.mask) || []
        }) || []

      const extractedRestorations = restorations?.map((r) => r.mask) || []

      return {
        id: id || "",
        meta,
        masks: {
          apical: extractedMask(apical).concat(
            extractedAdditions(AnnotationName.apical)
          ),
          caries: extractedMask(caries).concat(
            extractedAdditions(AnnotationName.caries)
          ),
          other: extractedMask(calculus)
            .concat(extractedRestorations)
            .concat(
              extractedAdditions(AnnotationName.calculus),
              extractedAdditions(AnnotationName.restorations)
            ),
        },
      }
    })

    yield put(
      setFullMouthImages({
        images: extractedImageData,
        resultStatus: ResultStatus.Success,
      })
    )
    yield put(setFullMouthResults(extractedData))
  } catch (error) {
    console.error(error)
    yield put(setFullMouthImagesResultStatus(ResultStatus.Error))
  }
}

function* requestFullMouthSaga({
  payload: resultId,
}: {
  type: FullMouthTypes.RequestFullMouth
  payload: string
}) {
  yield put(setCurrentFmxResultStatus(ResultStatus.Loading))
  yield put(setShownRadiographAnnotations("")) // Reset the individual radiograph annotations if they were shown
  yield call(cleanCachedImageSaga, {})

  try {
    const { data }: { data: RadiographSet } = yield call(
      requestGetRadiographSet,
      resultId
    )
    const { positions, changes, id, template, radiographs } = data

    yield put(
      setCurrentFmx({
        set: {
          positions,
          changes,
          id,
          template,
          radiographs,
        },
        resultStatus: ResultStatus.Success,
      })
    )

    yield call(requestFullMouthImagesSaga, radiographs)
    yield put(setImageIDBreadcrumb(`/radiograph-set/${data.id}`))
  } catch (error) {
    console.error(error)
    yield put(setCurrentFmxResultStatus(ResultStatus.Error))
  }
}

export default function* fullMouthSaga() {
  yield takeLatest(FullMouthTypes.RequestFullMouth, requestFullMouthSaga)
}
