import { createAction } from "typesafe-actions"

import {
  DataImage,
  IAcceptedFiles,
  IConflictedFile,
  IRejectedFiles,
  ToothWithDetection,
  UploadTypes,
} from "../types/uploadTypes"

export interface IMetaData {
  dateOfBirth: string | null
  imageDate: string | null
  patientName: string
  patientID: string
}

interface ActionGetDataImageSuccess {
  dataImage: DataImage
  newDataTooth: ToothWithDetection[]
  meta: IMetaData & {
    kind: string
    imageDateCreate: string
  }
}

export interface ActionWithData {
  meta: {
    patientID?: string
    patientName?: string
    dateOfBirth?: string
    patientUuid?: string
    externalImageUID?: string
    imageDateCreate: string
    imageDate: string
    fileName: string
  }
  data: File
}

export interface IReportProblem {
  info: {
    description: string
    userName: string
    email: string
  }
  screenshot: string
  url: string
}
export interface IUpload {
  id: string
  status: "success" | "error" | "total"
}

export const uploadActionNewImage = createAction(
  UploadTypes.UploadActionNewImage
)()
export const uploadActionPreloaderImage = createAction(
  UploadTypes.UploadActionPreloaderImage
)()
export const uploadActionError = createAction(UploadTypes.UploadActionError)()
export const uploadActionSuccess = createAction(
  UploadTypes.UploadActionSuccess
)()
export const uploadActionGetDataImageSuccess = createAction(
  UploadTypes.UploadActionDataImageSuccess
)<ActionGetDataImageSuccess>()
export const uploadActionWithData = createAction(
  UploadTypes.UploadActionWithData
)<ActionWithData | ActionWithData[]>()
export const uploadActionSaveUserMetaData = createAction(
  UploadTypes.UploadActionSaveUserMetadata
)<IMetaData>()
export const sendReportProblem = createAction(
  UploadTypes.UploadActionSendReportProblem
)<IReportProblem>()
export const setReportProblemId = createAction(
  UploadTypes.UploadActionSetReportProblemId
)<string>()
export const setShouldTakeScreenshot = createAction(
  UploadTypes.UploadActionSetShouldTakeScreenshot
)<boolean>()
export const setScreenshot = createAction(
  UploadTypes.UploadActionSetScreenshot
)<string>()
export const uploadActionSetImageSizeError = createAction(
  UploadTypes.UploadActionSetImageSizeError
)<string>()
export const setCurrentBatchUpload = createAction(
  UploadTypes.SetCurrentBatchUpload
)<IUpload>()
export const setTotalBatchUpload = createAction(
  UploadTypes.SetTotalBatchUpload
)<number>()
export const clearUploadFiles = createAction(UploadTypes.ClearUploadFiles)()
export const removeAcceptedFile = createAction(
  UploadTypes.RemoveAcceptedFile
)<number>()
export const setAcceptedFiles = createAction(
  UploadTypes.SetAcceptedFiles
)<IAcceptedFiles>()
export const setRejectedFiles = createAction(
  UploadTypes.SetRejectedFiles
)<IRejectedFiles>()
export const setConflictedFiles = createAction(
  UploadTypes.SetConflictedFiles
)<IConflictedFile>()
export const clearConflictedFile = createAction(
  UploadTypes.ClearConflictedFile
)<string>()
