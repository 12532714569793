import { RootState } from "core/store/configureStore"
import { Theme } from "../actions/user"
import { Kind } from "../types/serverDataTypes"
import { canHaveBoneLossPro } from "../../utilities/features"
import { createSelector } from "reselect"
import { LogoutReason, Role } from "../types/userTypes"
import { getIsIntegrated } from "./routes"
import localStorage from "library/utilities/localStorage"

export const getIsAuthenticated = (state: RootState) =>
  state.user.isAuthenticated
export const getUsername = (state: RootState) => state.user.username
export const getLoginFailed = (state: RootState) => state.user.loginFailed
export const getLoginFailedStatus = (state: RootState) => state.user.failStatus
export const getNewPasswordError = (state: RootState) =>
  state.user.newPasswordError
export const getOldPasswordError = (state: RootState) =>
  state.user.oldPasswordError
export const getResetPasswordStatus = (state: RootState) =>
  state.user.resetPasswordStatus
export const getMustResetPassword = (state: RootState) =>
  state.user.isMustResetPassword && !state.user.SSO
export const getLastCoachMarks = (state: RootState) => state.user.lastCoachMarks
export const getFullName = (state: RootState) => state.user.fullName
export const getEmail = (state: RootState) => state.user.email
export const getUserSSO = (state: RootState) => state.user.SSO
export const getServerError = (state: RootState) => state.user.serverError
export const getServerErrorMessage = (state: RootState) =>
  state.user.serverErrorMessage
export const getDefaultCompanyId = (state: RootState) =>
  state.user.defaultCompanyId
export const getHandlerName = (state: RootState) => state.user.handlerName
export const getHandlerHash = (state: RootState) => state.user.handlerHash
export const getKnownHandlers = (state: RootState) => state.user.knownHandlers
export const getCariesProProfile = (state: RootState) =>
  state.features.featureRememberHsm && localStorage.getItem("HSM")
    ? true
    : state.user.cariesPro
export const getBonelossProProfile = (state: RootState) =>
  state.features.featureBoneloss ? state.user.bonelossPro : null
export const getShowBonelossPro = (state: RootState) =>
  state.features.featureBoneloss &&
  state.user.bonelossPro !== null &&
  canHaveBoneLossPro(
    state.serverData.present.imageMeta.kind,
    state.features.featurePerioProForPeri
  )
export const getShowBoneLossLite = (state: RootState) =>
  (state.features.showFullPblForm && !state.features.hidePblClassification
    ? state.user.boneLossLite
    : true) &&
  canHaveBoneLossPro(
    state.serverData.present.imageMeta.kind,
    state.features.featurePerioProForPeri
  )
export const getUploadsRemaining = (state: RootState) =>
  state.user.uploadsRemaining
export const getWhatsNew = (state: RootState) => state.user.whatsNew
export const getTheme = (state: RootState) => state.user.theme
export const getCalculus = (state: RootState) =>
  state.features.featureCalculus &&
  (state.serverData.present.imageMeta.kind !== Kind.Peri ||
    state.features.featureCalculusOnPeri) &&
  state.user.calculus
// Only show true if it is activated on the backend and the user has permission for feature
export const getNervus = (state: RootState) =>
  state.features.featureNervus && state.user.nervus
export const getImpacted = (state: RootState) =>
  state.features.featureImpacted && state.user.impacted
export const getLicense = (state: RootState) => state.user.license
export const getLicenceExpire = (state: RootState) => state.user.licenceExpire
export const getShowDrawingMode = (state: RootState) =>
  state.features.featureDrawingMode &&
  (state.features.featureDrawingModeInPeri ||
    state.serverData.present.imageMeta.kind !== Kind.Peri) &&
  state.user.showDrawingMode
export const getBoneLossOnly = (state: RootState) => state.user.boneLossOnly
export const getShowInactiveBoneLossButton = (state: RootState) =>
  state.features.showInactivePblButton &&
  canHaveBoneLossPro(
    state.serverData.present.imageMeta.kind,
    state.features.featurePerioProForPeri
  )

export const getModalities = (state: RootState) => state.user.modalities
export const getNumberingSystem = (state: RootState) =>
  state.user.numberingSystem
export const getShowModalityChooser = (state: RootState) =>
  state.user.modalities.length > 1 &&
  (state.user.theme !== Theme.carestream ||
    state.serverData.present.imageMeta.kind !== Kind.Opg)

export const getShowToothBasedPeri = (state: RootState) =>
  state.user.toothBasedPeri
export const getRole = (state: RootState) => state.user.role
export const getGeneratedToken = (state: RootState) => state.user.generatedToken
export const getLoggedOutByTimeOut = (state: RootState) =>
  state.user.logoutReason === LogoutReason.Timeout
export const getLoggedOutByButton = (state: RootState) =>
  state.user.logoutReason === LogoutReason.Button

export const getIsPatientMatchingAllowed = (state: RootState) =>
  !!state.user.companyId
export const getIsIteroUser = (state: RootState) => state.user.isIteroUser
export const getIsIteroMidcUser = (state: RootState) =>
  state.user.isIteroMidcUser
export const getIsIteroScannerUser = (state: RootState) =>
  state.user.isIteroScannerUser

export const getCompanyId = (state: RootState) => state.user.companyId
export const getDoctorId = (state: RootState) => state.user.doctorId
export const getImpersonate = (state: RootState) => state.user.impersonate
export const getInstallerByDefault = (state: RootState) =>
  state.user.installerByDefault

export const getCanUpload = createSelector(
  [getInstallerByDefault, getIsIteroUser, getIsIntegrated],
  (installerByDefault, isIteroUser, isIntegrated) =>
    !installerByDefault && !isIteroUser && !isIntegrated
)

export const getContextQueryParams = createSelector(
  [getCompanyId, getDoctorId, getImpersonate],
  (companyId, doctorId, impersonate) => ({ companyId, doctorId, impersonate })
)

export const getHidePatientId = (state: RootState) => state.user.hidePatientId
export const getShowAlfaDocs = (state: RootState) =>
  state.user.showAlfaDocs && state.features.featureAlfaDocsIntegration
export const getAlfaDocsApiKey = (state: RootState) => state.user.alfaDocsApiKey
export const getUserIntegrationResultStatus = (state: RootState) =>
  state.user.userIntegrationResultStatus
export const getUserResultStatus = (state: RootState) =>
  state.user.userResultStatus
export const getIsOpenUserSettingsModal = (state: RootState) =>
  state.user.isOpenUserSettingsModal
export const getShowSmallScreenNotification = (state: RootState) =>
  state.user.showSmallScreenNotification
export const getIsPendoRunning = (state: RootState) => state.user.isPendoRunning
const getHasDemoParam = (state: RootState) => state.user.hasDemoParam

export const getDemoMode = createSelector(
  [getHasDemoParam, getRole],
  (hasDemoParam, role) => hasDemoParam || role === Role.Demo
)
export const getPlanType = (state: RootState) => state.user.planType
