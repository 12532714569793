import { ResultStatus } from "./dataStructureTypes"
import { FmxTemplate, SetPositioning } from "./patientTypes"
import { IMeta } from "./serverDataTypes"

export enum FullMouthTypes {
  SetInitialFullMouthState = "@@FullMouth/SetInitialFullMouthState",
  SetCurrentFmx = "@@FullMouth/SetCurrentFmx",
  SetCurrentFmxResultStatus = "@@FullMouth/SetCurrentFmxResultStatus",
  RequestFullMouth = "@@FullMouth/RequestFullMouth",
  SetFullMouthImages = "@@FullMouth/SetFullMouthImages",
  SetFullMouthImagesResultStatus = "@@FullMouth/SetFullMouthImagesResultStatus",
  SetFullMouthResults = "@@FullMouth/SetFullMouthResults",
  SetActiveFullMouthImage = "@@FullMouth/SetActiveFullMouthImage",
  SetShowFullMouthAnnotations = "@@FullMouth/SetShowFullMouthAnnotations",
}

export interface RequestRadiographSet {
  id: string
  created: string
  updated: string
  radiographs: string[]
  template: FmxTemplate
  positions: SetPositioning[] | null
  changes: SetPositioning[] | null
}

export interface CurrentFmx {
  id: string
  changes: SetPositioning[] | null
  positions: SetPositioning[] | null
  radiographs: string[]
  template: FmxTemplate
}

export interface CurrentFmxResult {
  set: CurrentFmx | null
  resultStatus: ResultStatus
}

export interface FullMouthImages {
  id: string
  meta?: IMeta
  masks: {
    apical: string[]
    caries: string[]
    other: string[]
  }
}

export interface FullMouthImagesResult {
  images: FullMouthImages[]
  resultStatus: ResultStatus
}
